import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/icons/iconfont.css";
//导入axios
import axios from "axios";
Vue.prototype.$axios = axios;
// 导入animate.css
import animated from "animate.css";

//默认样式清除引入
import "reset-css";
import "@/styles/index.scss"; // global css

//引入elementUI
// import ElementUI from "element-ui";//全局引入
// Vue.use(ElementUI);
import element from "./plugins/element"; //按需引入
import "element-ui/lib/theme-chalk/index.css";

Vue.use(element);
Vue.use(animated);

//引入qs
import qs from "qs";
Vue.prototype.$qs = qs;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

<template>
  <div class="footer">
    <div class="botCon">
      <div class="conLeft">
        <div class="title">
          <div class="item" @click="goToWhere('/', 0)">首页</div>
          <div class="line"></div>
          <div class="item" @click="goToPro('/programme')">场景应用</div>
          <div class="line"></div>
          <div class="item" @click="goToWhere('/entIn', 4)">入驻我们</div>
          <div class="line"></div>
          <div class="item" @click="goToWhere('/about', 3)">关于我们</div>
        </div>
        <div class="address item">
          地址： 浙江省杭州市滨江区建业路599号华业发展中心32楼
        </div>
        <div class="ask item">咨询： 0571-89973966或0571-89973977</div>
        <div class="cor item">合作： info@jrmall.cn</div>
      </div>
      <div class="conRight">
        <img src="@/assets/imgs/map.jpg" alt="" />
      </div>
    </div>
    <div class="bottoms">
      <div class="bot-item">
        <div class="company">Copyright © 浙江聚如信息技术有限公司</div>
        <div class="record">
          <img class="copy" src="@/assets/imgs/img_record.png" alt="" />
          <a href="https://beian.miit.gov.cn/" target="_blank" class="num">
            浙ICP备2022008704号-1</a
          >
        </div>
        <div class="record" style="margin-left: 20px">
          <img class="copy" src="@/assets/imgs/img_record.png" alt="" />
          <a href="https://beian.miit.gov.cn/" target="_blank" class="num">
            浙公安网备 33010802012447号</a
          >
        </div>
      </div>
    </div>
    <div class="aside acea-row row-column row-center">
      <div class="item back pointer" @click="goTop" v-if="isShow">
        <img src="@/assets/newImgs/backToTop.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShow: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      // 处理滚动事件的逻辑
      var t = document.documentElement.scrollTop || document.body.scrollTop;
      this.isShow = t >= 500;
    },
    //页面路由切换
    goToWhere(page, index) {
      if (index !== 2) {
        this.$router.push({
          path: page,
        });
      }
    },
    goToPro(page) {
      this.$router.push({
        path: page,
      });
    },
    goTop() {
      (function n() {
        var t = document.documentElement.scrollTop || document.body.scrollTop;
        if (t > 0) {
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
        }
      })();
      // var gotoTop = function () {
      //   var currentPosition =
      //     document.documentElement.scrollTop || document.body.scrollTop;
      //   currentPosition -= 20;
      //   if (currentPosition > 0) {
      //     window.scrollTo(0, currentPosition);
      //   } else {
      //     window.scrollTo(0, 0);
      //     clearInterval(timer);
      //     timer = null;
      //   }
      // };
      // var timer = setInterval(gotoTop, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  height: 340px;
  background: #2e3033;
}
.aside {
  width: 60px;
  height: 100%;
  position: fixed;
  z-index: 999;
  right: 20px;
  top: 0;
  .item {
    margin-top: 400px;
    img {
      width: 70px;
      height: 70px;
    }
  }
}

.botCon {
  display: flex;
  justify-content: center;

  .conLeft {
    width: 35%;
    min-width: 536px;

    .title {
      margin-top: 70px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .line {
        width: 12px;
        height: 2px;
        background: #ffffff;
        margin-left: 13px;
        margin-right: 13px;
      }

      .item:hover {
        color: #3cb8ff;
      }
    }

    .item {
      font-size: 16px;
      line-height: 22px;
      color: #ffffff;
      cursor: pointer;
    }

    .address {
      margin-top: 33px;
      width: 100%;
    }
    .ask {
      width: 100%;
      margin-top: 17px;
    }
    .cor {
      width: 100%;
      margin-top: 17px;
    }
  }

  .conRight {
    margin-top: 50px;
    width: 35%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    img {
      width: 368px;
      height: 194px;
    }
  }
}

.bottoms {
  margin-top: 32px;
  border-top: 1px solid rgba(221, 221, 221, 0.2);
  height: 64px;
  padding: 23px 0;

  .bot-item {
    width: 70%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .company {
      font-size: 16px;
      color: #92aeda;
      margin-right: 16px;
    }

    .record {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        display: inline-block;
        width: 18px;
        height: 18px;
      }
      .num {
        margin-left: 10px;
        font-size: 16px;
        color: #afafaf;
        &:hover {
          color: #e93323;
        }
      }
    }
  }
}
</style>

<template>
  <div>
    <div class="header">
      <div class="headerBox wrapper_1200 acea-row row-middle row-between">
        <div style="min-width: 900px" class="acea-row row-middle">
          <img
            src="@/assets/newImgs/logo.png"
            @click="goToWhere({ page: '/' }, 0)"
            alt=""
            class="logo pointer"
          />
          <div class="navBox acea-row row-middle u-ml46">
            <div
              class="navItem u-mr40 pointer"
              :class="{ active: pathName === item.page }"
              v-for="(item, index) in navList"
              :key="index"
              @mouseover="mouseOver(index)"
              @mouseleave="mouseLeave(index)"
              @click="goToWhere(item, index)"
            >
              <span> {{ item.title }}</span>
              <div
                v-if="index === 2"
                class="solveBox"
                :class="{ downSelect: showDown && index === 2 }"
              >
                <div class="solveLvBox">
                  <div
                    class="solveItem"
                    :class="{ active: index === solveActive }"
                    v-for="(item, index) in solveList"
                    :key="index"
                    @mouseover="solveMouseOver(index)"
                    @click="Gotuintroduction(index, item)"
                  >
                    <span>{{ item.name }}</span>
                  </div>
                </div>
                <div
                  v-if="solveActive === 0"
                  :class="{ rightSelect: showRight }"
                  class="asideBox"
                >
                  <div
                    class="asideItem"
                    v-for="(item, index) in asideList"
                    :key="index"
                    @click="goGift(item)"
                  >
                    {{ item.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="btn pointer" @click="openDialog">入驻我们</div>
      </div>
    </div>
    <myDialog :is-show="dialogShow" :pdt="0" @on-close="closeDialog">
      <div class="dialog_publish_main" slot="main">
        <div class="left-dialog acea-row row-column row-center-wrapper">
          <div class="qrBox u-mt28">
            <img
              src="https://oss.pinmallzj.com/image/maintain/2023/08/31/341689298200_x1-n4z204zbr7ff.png"
              alt=""
              class="qrImg"
            />
            <img
              src="https://oss.pinmallzj.com/image/maintain/2023/08/31/Vector-4dkt030xg4uy.png"
              alt=""
              class="position"
            />
          </div>
          <div class="infoBox u-mt34">
            <div class="info">
              扫描上方的二维码，即可进入聚如公众号。我们期待与您建立联系并展开进一步合作
            </div>
            <img
              src="https://oss.pinmallzj.com/image/maintain/2023/08/31/Vector-4dkt030xg4uy.png"
              alt=""
              class="position"
            />
          </div>
        </div>
        <div class="right-dialog">
          <div class="closeDiv">
            <span
              class="icon iconfont icon-guanbi close"
              @click="closeDialog"
            ></span>
          </div>
          <div class="title">预约入驻申请</div>
          <div class="title1 u-mb42">解决企业采购难题 实现降本增效</div>

          <div class="inp-item">
            <span>您的称呼</span>
            <input
              type="text"
              v-model="applyData.contact"
              placeholder="怎么称呼您"
            />
          </div>
          <div class="inp-item">
            <span>企业名称</span>
            <input
              type="text"
              v-model="applyData.name"
              placeholder="请填写公司名称"
            />
          </div>
          <div class="inp-item">
            <span>企业简称</span>
            <input
              type="text"
              :class="[nameWarn ? 'checkName' : '']"
              v-model="applyData.shorterName"
              placeholder="请填写企业简称(最多四个字)"
              @change="checkName"
            />
            <div v-if="nameWarn" class="warningTxt">
              请输入的企业简称控制在四字以内！
            </div>
          </div>
          <div class="inp-item">
            <span>手机号</span>
            <input
              type="text"
              v-model="applyData.phone"
              placeholder="方便后续沟通跟进"
            />
          </div>
          <div class="inp-item code">
            <span>验证码</span>
            <input
              type="text"
              v-model="applyData.captcha"
              placeholder="手机发送的验证码"
            />
            <span class="send" @click="getCode">{{ text }}</span>
          </div>
          <div class="inp-item">
            <span>设置密码</span>
            <input
              type="password"
              autocomplete="new-password"
              v-model="applyData.pwd"
              placeholder="创建商城后的登录密码"
            />
          </div>

          <div class="tip">提交成功后我们会通知您</div>
          <div class="btn" @click="submit">立即入驻</div>
        </div>
      </div>
    </myDialog>
  </div>
</template>

<script>
import myDialog from "@/components/dialog.vue";
import sendVerifyCode from "@/mixins/SendVerifyCode";
import { entSettled, getCodeApi } from "@/api/user.js";
export default {
  mixins: [sendVerifyCode],
  components: {
    myDialog,
  },
  watch: {
    $route: {
      handler(val, oldVal) {
        console.log(val, "val");
        if (
          [
            "/JRintroduction",
            "/PJintroduction",
            "/ZJTintroduction",
            "/bakingintroduction",
            "/WMintroduction",
            "/WWTintroduction",
            "/county",
          ].includes(val.fullPath)
        ) {
          this.pathName = "/solve";
        } else {
          this.pathName = val.fullPath;
        }
      },
    },
  },
  data() {
    return {
      dialogShow: false,
      showDown: false,
      showRight: false,
      solveActive: null,
      applyData: {
        name: "",
        shorterName: "",
        contact: "",
        phone: "",
        captcha: "",
        pwd: "",
      },
      nameWarn: false,
      pathName: "/", //默认为首页
      navList: [
        {
          title: "首页",
          page: "/",
        },
        {
          title: "场景应用",
          page: "/programme",
        },
        {
          title: "解决方案",
          page: "/solve",
        },
        {
          title: "入驻我们 ",
          page: "/entIn",
        },
        {
          title: "关于我们",
          page: "/about",
        },
      ],
      solveList: [
        {
          name: "礼赠品行业解决方案",
          url: "",
        },
        {
          name: "烘焙行业解决方案",
          url: "/bakingintroduction",
        },
        {
          name: "民营口腔诊所解决方案",
          url: "/WMintroduction",
        },
        {
          name: "县域经济解决方案",
          url: "/county",
        },
        {
          name: "欧洲百货店解决方案",
          url: "/WWTintroduction",
        },
      ],
      asideList: [
        {
          name: "聚如SaaS（礼品服务商）",
          url: "/JRintroduction",
        },
        {
          name: "品聚SaaS（品牌供应商）",
          url: "/PJintroduction",
        },
        {
          name: "政企通SaaS（终端客户）",
          url: "/ZJTintroduction",
        },
      ],
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll); // 监听页面滚动
  },
  methods: {
    goToWhere(item, index) {
      if (index !== 2) {
        this.pathName = item.page;
        this.$router.push({
          path: item.page,
        });
      }
    },
    mouseOver(index) {
      if (index === 2) {
        this.showDown = true;
      }
    },
    mouseLeave(index) {
      if (index === 2) {
        this.showDown = false;
        this.solveActive = null;
      }
    },
    solveMouseOver(index) {
      this.solveActive = index;
      this.showDown = true;
      if (index === 0) {
        this.showRight = true;
      }
    },
    //企业简称校验
    checkName() {
      if (this.applyData.shorterName.length > 4) {
        this.nameWarn = true;
      } else {
        this.nameWarn = false;
      }
    },
    //发送验证码
    getCode() {
      let that = this;
      let reg = /^1[3456789]{1}\d{9}$/;

      if (this.applyData.phone == "" || !reg.test(this.applyData.phone)) {
        return this.$message({
          message: "手机号码不正确，请重新输入!",
          type: "error",
        });
      }

      const data = that.$qs.stringify({ phone: that.applyData.phone - "" });
      if (!this.disabled) {
        this.disabled = true;
        getCodeApi(data)
          .then((res) => {
            if (res.code === 200) {
              this.$message({
                message: "发送成功",
                type: "success",
              });
              that.sendCode();
            } else if (res.code === 500) {
              this.$message.error(res.message);
            }
          })
          .catch((error) => {
            this.$message.error("发送失败");
          });
      }
    },
    //点击申请
    submit() {
      if (
        this.applyData.name == "" ||
        this.applyData.shorterName == "" ||
        this.applyData.contact == "" ||
        this.applyData.phone == "" ||
        this.applyData.captcha == "" ||
        this.applyData.pwd == ""
      ) {
        return this.$message.warning("请完善入驻申请信息!!!");
      }
      if (this.applyData.shorterName.length > 4) {
        return (this.nameWarn = true);
      }
      entSettled(this.applyData)
        .then((res) => {
          if (res.code !== 200) {
            this.$message.error(res.message);
          } else {
            this.$message({
              message: "申请成功，请等待平台审核!",
              type: "success",
            });
            this.applyData.name == "";
            this.applyData.shorterName == "";
            this.applyData.contact == "";
            this.applyData.phone == "";
            this.applyData.captcha == "";
            this.applyData.pwd == "";
            this.dialogShow = false;
            this.move();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //禁止滚动条滚动
    stop() {
      var mo = function (e) {
        e.preventDefault();
      };
      document.body.style.overflow = "hidden";
      document.addEventListener("touchmove", mo, false);
    },
    //恢复滚动条滚动
    move() {
      var mo = function (e) {
        e.preventDefault();
      };
      document.body.style.overflow = "";
      //出现滚动条
      document.removeEventListener("touchmove", mo, false);
    },
    //打开弹窗
    openDialog() {
      this.dialogShow = true;
      this.stop();
    },
    //关闭弹窗
    closeDialog() {
      this.dialogShow = false;
      this.move();
    },
    goGift(item) {
      this.pathName = "/solve";
      this.$router.push({
        path: item.url,
      });
      this.showDown = false;
    },
    Gotuintroduction(index, item) {
      if (index !== 0) {
        this.pathName = "/solve";
        this.$router.push({
          path: item.url,
        });
        this.showDown = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  background: #f4f5f8;
  width: 100%;
  height: 92px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  transition: all 0.2s linear;

  &:hover {
    box-shadow: 0px 5px 14px 0px rgba(204, 214, 229, 0.9);
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: #000;
    opacity: 0.1;
  }
  .headerBox {
    height: 100%;
    .logo {
      width: 175px;
      height: 40px;
    }
    .navBox {
      .navItem {
        height: 92px;
        font-size: 16px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #16181a;
        line-height: 92px;
        position: relative;
        &.active {
          color: #3266f6;
          position: relative;
          &::before {
            content: "";
            background: #3266f6;
            width: 100%;
            height: 2px;
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
            border-radius: 4px;
          }
        }

        &:hover {
          color: #3266f6;
          position: relative;
          &::before {
            content: "";
            background: #3266f6;
            width: 100%;
            height: 2px;
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
            border-radius: 4px;
          }
        }

        .solveBox {
          position: absolute;
          top: 91px;
          left: 50%;
          z-index: 11;
          transform: translateX(-25%);
          width: 480px;
          height: 0;
          overflow-y: hidden;
          border-radius: 0px 0px 4px 4px;

          &.downSelect {
            height: 310px;
            transition: all 0.2s linear;
          }
          .solveLvBox {
            width: 50%;
            box-shadow: 1px 2px 6px 0px rgba(0, 0, 0, 0.14);

            .solveItem:last-child {
              border-radius: 0px 0px 4px 4px;
            }
            .solveItem {
              background: #f4f5f8;
              user-select: none;
              text-align: center;
              font-size: 16px;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
              color: #333333;
              width: 100%;
              height: 62px;
              line-height: 62px;
              position: relative;
              &.active {
                background: #fff;
                color: #3266f6;
              }
            }
          }

          .asideBox {
            position: absolute;
            top: 0;
            left: 240px;
            width: 0;
            height: 186px;
            background: #f4f5f8;
            box-shadow: 1px 2px 6px 0px rgba(0, 0, 0, 0.14);
            border-radius: 0px 0px 4px 4px;
            overflow-x: hidden;
            overflow-y: hidden;
            transition: all 0.2s linear;
            &.rightSelect {
              width: 240px;
              transition: all 0.2s linear;
            }
            .asideItem {
              user-select: none;
              text-align: center;
              font-size: 16px;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
              color: #333333;
              height: 62px;
              line-height: 62px;
              &:hover {
                background: #fff;
                color: #3266f6;
              }
            }
          }
        }
      }
    }
    .btn {
      user-select: none;
      width: 132px;
      height: 43px;
      background: #3266f6;
      border-radius: 39px;
      font-size: 18px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      text-align: center;
      line-height: 43px;
    }
  }
}

.dialog_publish_main {
  min-width: 960px;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  .closeDiv {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;
    margin-top: 10px;
    .close {
      display: inline-block;
      cursor: pointer;
    }
  }

  .left-dialog {
    width: 50%;
    .qrBox {
      position: relative;
      .qrImg {
        width: 276px;
        height: 276px;
      }
      .position {
        width: 70px;
        height: 64px;
        position: absolute;
        top: -12%;
        left: -10%;
        z-index: -1;
      }
    }
    .infoBox {
      position: relative;
      .info {
        width: 268px;
        color: #333a45;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }
      .position {
        width: 20px;
        height: 19px;
        position: absolute;
        top: -28%;
        left: -4%;
        z-index: -1;
      }
    }
  }

  .right-dialog {
    width: 50%;

    div:nth-child(3) {
      margin-top: 14px !important;
    }

    .title {
      font-size: 19px;
      font-weight: 500;
      color: #000000;
    }

    .title1 {
      margin-top: 10px;
      font-size: 14px;
      font-weight: 500;
      color: #666666;
    }

    .inp-item {
      margin-top: 16px;
      position: relative;

      span {
        display: inline-block;
        width: 52px;
        font-size: 13px;
        font-weight: 500;
        color: #666666;
      }

      input {
        margin-left: 23px;
        width: 244px;
        height: 32px;
        background: #ffffff;
        padding: 7px 16px;
        border: none;
        outline: none;
        border-radius: 4px;
        border: 1px solid #eeeeee;
      }

      input::-webkit-input-placeholder {
        color: #999999;
      }
      input::-webkit-input-placeholder {
        color: #999999;
      }
      input:-ms-input-placeholder {
        color: #999999;
      }

      input:focus {
        outline: 1px solid #42a7ff;
      }

      .warningTxt {
        color: #f56c6c;
        font-size: 12px;
        line-height: 1;
        padding-top: 4px;
        position: absolute;
        top: 51%;
        left: 16.5%;
      }
    }

    .checkName {
      border: 1px solid #ff0d0d !important;
    }
    .checkName:focus {
      outline: none !important;
    }

    .code {
      display: flex;
      align-items: center;
      input {
        width: 156px;
        height: 32px;
      }

      .send {
        cursor: pointer;
        margin-left: 8px;
        display: inline-block;
        width: 80px;
        height: 32px;
        border: 1px solid #125afd;
        border-radius: 4px;
        text-align: center;
        line-height: 32px;
        font-size: 13px;
        color: #107dff;
      }
    }

    .tip {
      font-size: 11px;
      font-weight: 500;
      color: #999999;
      margin-top: 8px;
    }

    .btn {
      margin-top: 32px;
      width: 323px;
      height: 45px;
      text-align: center;
      line-height: 45px;
      background: linear-gradient(90deg, #5388f7 0%, #335ae9 100%);
      font-size: 13px;
      color: #ffffff;
      cursor: pointer;
    }
  }
}
</style>

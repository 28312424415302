import { Carousel, CarouselItem, Message, Select, Option, Loading, Button } from "element-ui";
const element = {
  install: function (Vue) {
    Vue.use(Carousel);
    Vue.use(CarouselItem);
    Vue.prototype.$message = Message;
	Vue.use(Select);
	Vue.use(Option);
	Vue.use(Loading);
	Vue.use(Button);
  },
};
export default element;

import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/programme",
    name: "programme",
    component: () => import("../views/programme.vue"),
  },
  {
    path: "/welfare",
    name: "welfare",
    component: () => import("../views/welfare.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/aboutUs.vue"),
  },
  {
    path: "/supplier",
    name: "supplier",
    component: () => import("../views/supplier.vue"),
  },
  {
    path: "/entIn",
    name: "entIn",
    component: () => import("../views/entIn.vue"),
  },
  {
    path: "/JRintroduction",
    name: "JRintroduction",
    component: () => import("../views/gift/JRintroduction.vue"),
  },
  {
    path: "/PJintroduction",
    name: "PJintroduction",
    component: () => import("../views/gift/PJintroduction.vue"),
  },
  {
    path: "/ZJTintroduction",
    name: "ZJTintroduction",
    component: () => import("../views/gift/ZJTintroduction.vue"),
  },
  {
    path: "/bakingintroduction",
    name: "bakingintroduction",
    component: () => import("../views/gift/bakingintroduction.vue"),
  },
  {
    path: "/WMintroduction",
    name: "WMintroduction",
    component: () => import("../views/gift/WMintroduction.vue"),
  },
  {
    path: "/WWTintroduction",
    name: "WWTintroduction",
    component: () => import("../views/gift/WWTintroduction.vue"),
  },
  {
    path: "/county",
    name: "county",
    component: () => import("../views/gift/county.vue"),
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
  //配置滚动行为，跳转到新的路由界面滚动条的位置
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;

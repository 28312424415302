var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dialog"},[(_vm.isShow)?_c('div',{staticClass:"dialog-cover back",on:{"click":_vm.closeMyself}}):_vm._e(),_c('transition',{attrs:{"name":"drop"}},[(_vm.isShow)?_c('div',{staticClass:"dialog-content",style:({
        top: _vm.topDistance + '%',
        width: _vm.widNum + '%',
        left: _vm.leftSite + '%',
      })},[_c('div',{staticClass:"dialog_head back"},[_vm._t("header")],2),_c('div',{staticClass:"dialog_main",style:({
          paddingTop: _vm.pdt + 'px',
          paddingBottom: _vm.pdb + 'px',
          height: _vm.heiNum + 'px',
        })},[_vm._t("main",function(){return [_vm._v("弹窗内容")]})],2),_c('div',{staticClass:"foot_close",on:{"click":_vm.closeMyself}},[_c('div',{staticClass:"close_img back"})])]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
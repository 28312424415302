<template>
  <div id="app">
    <myheader></myheader>
    <router-view />
    <myfooter></myfooter>
  </div>
</template>

<script>
import myheader from "@/components/header.vue";
import myfooter from "@/components/foot.vue";
export default {
  components: {
    myheader,
    myfooter,
  },
  mounted() {
    if (this._isMobile()) {
      let url = "www.jrmall.cn/mobel/#/index";
      let path = window.location.protocol + "//" + url;
      window.location.href = path;
    }
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
};
</script>

<style lang="scss">
.app {
  height: 100%;
}
</style>

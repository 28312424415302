import axios from "axios";

// let baseURL = "https://juru.dev.jrmall.cn/";
let baseURL =
  process.env.NODE_ENV == "production"
    ? "https://juru.prod.jrmall.cn/"
    : "https://juru.dev.jrmall.cn/";
// 创建基础地址
const service = axios.create({
  baseURL,
});
service.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      return response.data;
    } else {
      Promise.reject();
    }
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);
export default service;

// 引入request
import request from "@/utils/request";

// 企业填写入驻申请
export function entSettled(data) {
  return request({
    url: "/api/admin/enterprise/apply/add",
    method: "post",
    data,
  });
}

//获取验证码
export function getCodeApi(data) {
  return request({
    url: "/api/front/sendCode",
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data,
  });
}

// 获取供应商分类
export function categoryList(data) {
  return request({
    url: "/api/admin/supplier/apply/category/list",
    method: "get",
    data,
  });
}

// 供应商入驻申请
export function supplierSettled(data) {
  return request({
    url: "/api/admin/supplier/apply/add",
    method: "post",
    data,
  });
}
<template>
  <div class="home">
    <div class="swiper">
      <el-carousel
        class="carousel"
        arrow="never"
        :autoplay="true"
        :interval="3000"
        trigger="click"
        :height="imgHeight"
      >
        <el-carousel-item>
          <div class="swiperItem">
            <img
              class="bgImg"
              ref="imgH"
              src="https://oss.pinmallzj.com/image/maintain/2023/08/31/Rectanglex1-p4a8mo6kn93p.png"
              alt=""
              @load="imgLoad"
            />
            <div
              class="wrapper_1200 acea-row row-column row-center"
              style="height: 100%"
            >
              <div class="name">浙江聚如</div>
              <div class="name u-mt16">产业数字化转型服务商</div>
              <div class="info u-mt32">
                围绕不同产业核心需求，构建产业数字化生态圈，推动企业数字化转型升级。
              </div>
              <div
                class="btns u-mt32 acea-row row-between row-middle pointer"
                @click.stop="goToWhere('/entIn')"
              >
                <div class="left">立即体验</div>
                <div class="right acea-row row-center-wrapper">
                  <img src="@/assets/newImgs/rightArrow1.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="swiperItem">
            <img
              class="bgImg"
              ref="imgH"
              src="https://oss.pinmallzj.com/image/maintain/2023/08/31/Rectanglex1-p4a8mo6kn93p.png"
              alt=""
              @load="imgLoad"
            />
            <div
              class="wrapper_1200 acea-row row-column row-center"
              style="height: 100%"
            >
              <div class="name">浙江聚如</div>
              <div class="name u-mt16">产业数字化转型服务商</div>
              <div class="info u-mt32">
                围绕不同产业核心需求，构建产业数字化生态圈，推动企业数字化转型升级。
              </div>
              <div
                class="btns u-mt32 acea-row row-between row-middle pointer"
                @click.stop="goToWhere('/entIn')"
              >
                <div class="left">立即体验</div>
                <div class="right acea-row row-center-wrapper">
                  <img src="@/assets/newImgs/rightArrow1.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="swiperItem">
            <img
              class="bgImg"
              ref="imgH"
              src="https://oss.pinmallzj.com/image/maintain/2023/08/31/Rectanglex1-p4a8mo6kn93p.png"
              alt=""
              @load="imgLoad"
            />
            <div
              class="wrapper_1200 acea-row row-column row-center"
              style="height: 100%"
            >
              <div class="name">浙江聚如</div>
              <div class="name u-mt16">产业数字化转型服务商</div>
              <div class="info u-mt32">
                围绕不同产业核心需求，构建产业数字化生态圈，推动企业数字化转型升级。
              </div>
              <div
                class="btns u-mt32 acea-row row-between row-middle pointer"
                @click.stop="goToWhere('/entIn')"
              >
                <div class="left">立即体验</div>
                <div class="right acea-row row-center-wrapper">
                  <img src="@/assets/newImgs/rightArrow1.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <div class="toolBox">
        <div class="toolLvBox acea-row row-middle row-center">
          <div
            class="toolItem u-pt25 u-mr20 pointer"
            v-for="(item, index) in toolList"
            @click="setActiveItem(index)"
            :key="index"
          >
            <div class="topBox acea-row row-middle">
              <img :src="item.icon" alt="" />
              <span class="u-ml4 name">{{ item.name }}</span>
            </div>
            <div class="subName u-mt6">{{ item.subName }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="technologyBox" ref="tech">
      <div class="techSwiperBox animated fadeInUp" v-show="techShow">
        <el-carousel
          ref="carouselRef"
          arrow="never"
          :autoplay="true"
          :interval="4000"
          trigger="click"
          height="410px"
        >
          <el-carousel-item v-for="(item, index) in techList" :key="index">
            <div class="swiperItem acea-row row-middle">
              <div class="leftBox">
                <img :src="item.pic" alt="" />
              </div>
              <div class="rightBox u-pt25">
                <div class="btns u-pl4 acea-row row-middle pointer">
                  <div class="btn acea-row row-middle row-center">
                    <img src="@/assets/newImgs/Frame.png" alt="" />
                    <span class="u-ml4">全新版本</span>
                  </div>
                  <div class="u-ml8" style="color: #3d3d3d; font-size: 12px">
                    不断提升产品竞争力
                  </div>
                </div>
                <div class="title pointer u-mt24">{{ item.name }}</div>
                <div class="info pointer u-mt12">{{ item.info }}</div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="swiper-zhishi acea-row row-center-wrapper">
        <div
          v-for="(item, index) in techList"
          :key="index"
          class="zhishiItem u-mr26 pointer"
          @click="setActiveItem(index)"
          :class="{ swiperActive: swiperCurrentIndex === index }"
        ></div>
      </div>
    </div>
    <div class="manySceneBox" ref="scene">
      <div class="title animated zoomIn" v-show="sceneImgShow">
        针对不同产业场景
      </div>
      <div class="subTitle u-mt10 animated zoomIn" v-show="sceneImgShow">
        助力多种产业实现数字化转型升级
      </div>
      <div class="bgBox wrapper_1200 animated zoomIn" v-show="sceneImgShow">
        <div class="bgImg acea-row row-center row-middle">
          <img
            src="https://oss.pinmallzj.com/image/maintain/2023/08/10/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20230810112907-3j3zst65tyr2.png"
            alt=""
          />
        </div>
        <div class="bgListBox acea-row">
          <div
            class="bgListItem pointer acea-row"
            v-for="(item, index) in sceneList"
            :key="index"
            @click="goToWhere(item.url)"
          >
            <img :src="item.icon" class="iconImg" alt="" />
            <div class="rightBox u-ml12">
              <div class="name">{{ item.name }}</div>
              <div class="subName">{{ item.subName }}</div>
              <div class="more u-mt15">点击了解</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="stepBox" ref="stepBox">
      <div class="title animated fadeInUp" v-show="stepShow">浙江聚如</div>
      <div class="subName u-mt14 animated fadeInUp" v-show="stepShow">
        不断拓展产业覆盖范围，努力谋求更长远的发展
      </div>
      <div
        class="stepList wrapper_1200 acea-row row-between-wrapper animated fadeInUp"
        v-show="stepShow"
      >
        <div
          v-for="(item, index) in stepList"
          :key="index"
          class="acea-row row-middle"
        >
          <div
            class="stepItem pointer acea-row row-center row-bottom"
            :style="{ backgroundImage: 'url(' + item.pic + ')' }"
          >
            <div class="u-mb15">{{ item.name }}</div>
          </div>
          <div class="arrow u-ml16" v-if="index + 1 !== stepList.length">
            <img src="@/assets/newImgs/rightArrow.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="juruBox acea-row row-column row-center-wrapper">
      <img
        class="chinese"
        src="https://oss.pinmallzj.com/image/maintain/2023/08/31/%E6%B5%99%E6%B1%9F%E8%81%9A%E5%A6%82%E7%BB%99%E6%82%A8%E6%97%A0%E9%99%90%E5%8F%AF%E8%83%BD-r1khn9uwjslp.png"
        alt=""
      />
      <img
        class="eng u-mt20"
        src="https://oss.pinmallzj.com/image/maintain/2023/08/31/ZhejiangxJuruxInformationxTechnologyxCoxxxLtdx-2iqqr51wgq9v.png"
        alt=""
      />
    </div>
    <div class="industryBox acea-row row-middle row-center" ref="industry">
      <div
        class="leftBox acea-row row-between u-mr46 animated fadeInLeft"
        v-show="industryLeftShow"
      >
        <div
          class="industryItem pointer acea-row row-column row-middle"
          v-for="(item, index) in industryLeftList"
          :key="index"
        >
          <img :src="item.icon" alt="" />
          <div class="name">{{ item.name }}</div>
          <div class="subName u-mt8">{{ item.subName }}</div>
        </div>
      </div>
      <div class="centerBox"></div>
      <div
        class="rightBox acea-row row-between u-ml46 animated fadeInRight"
        v-show="industryRightShow"
      >
        <div
          class="industryItem pointer acea-row row-column row-middle"
          v-for="(item, index) in industryRightList"
          :key="index"
        >
          <img :src="item.icon" alt="" />
          <div class="name">{{ item.name }}</div>
          <div class="subName u-mt8">{{ item.subName }}</div>
        </div>
      </div>
    </div>
    <div class="cooperateBox" ref="cooperate">
      <div class="title animated fadeInUp" v-show="cooperateShow">
        战略合作伙伴
      </div>
      <div
        class="entList wrapper_1200 acea-row row-between animated fadeInUp"
        v-show="cooperateShow"
      >
        <div
          class="entItem u-mb40 pointer"
          v-for="(item, index) in entList"
          :key="index"
        >
          <div class="name u-pl20">{{ item.name }}</div>
          <div class="subName u-pl20">{{ item.subName }}</div>
        </div>
      </div>
      <div class="settledBox acea-row row-middle row-between">
        <div>
          <div class="name">企业入驻申请</div>
          <div class="subName u-mt5">
            即刻免费搭建商城，开启您的企业数字化转型之路！
          </div>
        </div>
        <div class="btn pointer" @click="goToWhere('/entIn')">企业入驻申请</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      imgHeight: "0",
      swiperCurrentIndex: 0,
      techShow: false,
      sceneImgShow: false,
      stepShow: false,
      cooperateShow: false,
      industryLeftShow: false,
      industryRightShow: false,
      toolList: [
        {
          name: "技术构架",
          subName: "稳定 卓越 创新",
          icon: "https://oss.pinmallzj.com/image/maintain/2023/09/07/icon24-a3rv3c64vaf3.png",
        },
        {
          name: "场景应用",
          subName: "全面 灵活 交互",
          icon: "https://oss.pinmallzj.com/image/maintain/2023/09/07/%E5%9C%BA%E6%99%AF%E5%BA%94%E7%94%A8-0gf0czfb5v26.png",
        },
        {
          name: "数据管理",
          subName: "稳定 安全 准确",
          icon: "https://oss.pinmallzj.com/image/maintain/2023/09/07/icon02-nedyfgstqi03.png",
        },
        {
          name: "用户体验",
          subName: "便捷 灵活 高效",
          icon: "https://oss.pinmallzj.com/image/maintain/2023/09/07/icon11-qdwlyj4i53er.png",
        },
      ],
      changeList: [
        {
          text: "自主特色小程序界面",
        },
        {
          text: "一键搭建PC商城",
        },
        {
          text: "大数据后台界面",
        },
      ],
      techList: [
        {
          name: "技术架构",
          info: "我们通过精心的设计，保证系统的高可靠性和稳定性；系统采用多层次的安全防护措施，保证用户数据的高隐私性；我们持续关注技术发展、不断进行技术创新，保证技术架构具有良好的扩展性，为用户提供更多创新型的产品和服务。",
          pic: "https://oss.pinmallzj.com/image/maintain/2023/09/07/jishujiagou-rbgufe3pvqmj.png",
        },
        {
          name: "多场景应用",
          info: "产品设计灵活且可扩展，支持应对不同场景的多变需求，助力客户快速适应业务发展和市场变化；注重数据集成与共享，可将不同场景的数据有效整合，实现数据高效管理和应用。",
          pic: "https://oss.pinmallzj.com/image/maintain/2023/08/09/Framex5715-b531u1id8zdq.png",
        },
        {
          name: "数据管理",
          info: "支持数据集中存储，确保数据的完整性；采取多重安全措施，确保数据的隐私性；支持数据备份与恢复，保障数据的稳定性；支持数据的灵活导入和导出，实现数据的有效流通与共享。",
          pic: "https://oss.pinmallzj.com/image/maintain/2023/08/09/Framex5716-1x8tb71siuqy.png",
        },
        {
          name: "用户体验",
          info: "我们将用户体验融入产品开发的每个环节，从客户角度出发，解决客户痛点；我们重视用户反馈，保证用户及时获得帮助和支持。我们时刻关注市场变化，全方位洞察用户需求，不断优化用户体验。",
          pic: "https://oss.pinmallzj.com/image/maintain/2023/08/09/Framex5717-andaq2oo6346.png",
        },
      ],
      sceneList: [
        {
          name: "聚如SaaS",
          subName: "礼品服务商业务场景",
          icon: require("@/assets/newImgs/juru1.png"),
          url: "/JRintroduction",
        },
        {
          name: "蔚美SaaS",
          subName: "民营口腔诊所业务场景",
          icon: require("@/assets/newImgs/weimei1.png"),
          url: "/WMintroduction",
        },
        {
          name: "暖点SaaS",
          subName: "烘焙业务场景",
          icon: require("@/assets/newImgs/duandian.png"),
          url: "/bakingintroduction",
        },
        {
          name: "物物通SaaS",
          subName: "欧洲百货店业务场景",
          icon: require("@/assets/newImgs/wulianwnag.png"),
          url: "/WWTintroduction",
        },
        {
          name: "县有良品SaaS",
          subName: "县域经济业务场景",
          icon: require("@/assets/newImgs/xianyouliangpin.png"),
          url: "/county",
        },
        {
          name: "政企通SaaS",
          subName: "企事业单位业务场景",
          icon: require("@/assets/newImgs/zqt.png"),
          url: "/ZJTintroduction",
        },
        {
          name: "品聚SaaS",
          subName: "品牌供应商业务场景",
          icon: require("@/assets/newImgs/pj.png"),
          url: "/PJintroduction",
        },
      ],
      stepList: [
        {
          name: "项目洽谈",
          pic: "https://oss.pinmallzj.com/image/maintain/2023/08/10/%E9%A1%B9%E7%9B%AE%E6%B4%BD%E8%B0%88-q852ax98680h.png",
        },
        {
          name: "需求分析",
          pic: "https://oss.pinmallzj.com/image/maintain/2023/08/10/%E9%9C%80%E6%B1%82%E5%88%86%E6%9E%90-5hm8aum4pggf.png",
        },
        {
          name: "方案输出",
          pic: "https://oss.pinmallzj.com/image/maintain/2023/08/10/%E6%96%B9%E6%A1%88%E8%BE%93%E5%87%BA-ul7fhtilr9hw.png",
        },
        {
          name: "设计开发",
          pic: "https://oss.pinmallzj.com/image/maintain/2023/08/10/%E8%AE%BE%E8%AE%A1%E5%BC%80%E5%8F%91-y0fzkuoxobjd.png",
        },
        {
          name: "优化迭代",
          pic: "https://oss.pinmallzj.com/image/maintain/2023/08/10/%E4%BC%98%E5%8C%96%E8%BF%AD%E4%BB%A3-r4le0m09d46a.png",
        },
        {
          name: "数据复盘",
          pic: "https://oss.pinmallzj.com/image/maintain/2023/08/10/%E6%95%B0%E6%8D%AE%E5%A4%8D%E7%9B%98-44okgz762ku6.png",
        },
        {
          name: "项目上线",
          pic: "https://oss.pinmallzj.com/image/maintain/2023/08/10/%E9%A1%B9%E7%9B%AE%E4%B8%8A%E7%BA%BF-kh52dzgateci.png",
        },
      ],
      entList: [
        {
          name: "中国邮政储蓄银行股份有限公司浙江省分行",
          subName: "Postal Savings Bank of China",
        },
        {
          name: "杭州金融投资集团",
          subName: "Hangzhou Investment Holdings Co., Ltd.",
        },
        {
          name: "浙江省国际贸易集团",
          subName: "Zhejiang International Business Group Co., Ltd.",
        },
        {
          name: "浙江物产集团",
          subName: "Zhejiang Materials Industrial Group Co., Ltd.",
        },
        {
          name: "浙江农合集团有限责任公司",
          subName: "Zhejiang Nonghe Group Co., Ltd.",
        },
        {
          name: "中创文贸(北京)文物艺术品有限公司",
          subName:
            "Zhongchuang Culture and Trade (Beijing) Cultural Relics and Artworks Co., Ltd.",
        },
        {
          name: "县学研究院",
          subName:
            "Xian Xue (Beijing) e-commerce Technology Research Institute",
        },
      ],
      industryLeftList: [
        {
          name: "供应商行业",
          subName: "Vendor",
          icon: require("@/assets/newImgs/Component 15.png"),
        },
        {
          name: "烘焙行业",
          subName: "Baking",
          icon: require("@/assets/newImgs/Component 16.png"),
        },
        {
          name: "童装行业",
          subName: "Children's clothing",
          icon: require("@/assets/newImgs/Component 19.png"),
        },
        {
          name: "口腔行业",
          subName: "Dental industry",
          icon: require("@/assets/newImgs/Component 20.png"),
        },
      ],
      industryRightList: [
        {
          name: "医美行业",
          subName: "Medical beauty",
          icon: require("@/assets/newImgs/Component 13.png"),
        },
        {
          name: "生鲜行业",
          subName: "Fresh",
          icon: require("@/assets/newImgs/Component 17.png"),
        },
        {
          name: "服装行业",
          subName: "Clothing",
          icon: require("@/assets/newImgs/Component 14.png"),
        },
        {
          name: "五金行业",
          subName: "Hardware",
          icon: require("@/assets/newImgs/Component 18.png"),
        },
      ],
    };
  },

  methods: {
    setActiveItem(index) {
      // console.log(index, "index");
      this.swiperCurrentIndex = index;
      const carousel = this.$refs.carouselRef;
      carousel.setActiveItem(index);
    },
    //页面路由切换
    goToWhere(page, index) {
      if (index !== 2) {
        this.$router.push({
          path: page,
        });
      }
    },
    imgLoad() {
      this.$nextTick(() => {
        this.imgHeight = `${this.$refs.imgH.height}px`;
      });
    },
    handleScroll() {
      // 实现当滚动到指定位置，触发动画
      // let scrollTop =  window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop // 获取窗口滚动条高度
      // this.gdjz('earth', 'earth animated bounceInDown', 50)
      let _this = this;
      let refArray = [
        { ref: "tech", show: "techShow", delayPos: 250 },
        {
          ref: "scene",
          show: "sceneImgShow",
          delayPos: 250,
        },
        {
          ref: "stepBox",
          show: "stepShow",
          delayPos: 500,
        },
        {
          ref: "cooperate",
          show: "cooperateShow",
          delayPos: 550,
        },
        {
          ref: "industry",
          show: "industryLeftShow",
          delayPos: 250,
        },
        {
          ref: "industry",
          show: "industryRightShow",
          delayPos: 250,
        },
      ];
      refArray.forEach((r, i) => {
        _this.gdjz(r.ref, r.delayPos, () => {
          _this[r.show] = true;
        });
      });
    },
    gdjz(div, offset, callback) {
      let dom = this.$refs[div]; // 等同于document.querySelector('.earth') true
      if (dom) {
        var a, b, c, d;
        d = dom.offsetTop; // 元素距离相对父级的高度，这里父级指的是body
        a = eval(d + offset);
        b =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop; //  获取窗口滚动条高度
        c = document.documentElement.clientHeight || document.body.clientHeight; // 获取浏览器可视区的高度
        if (b + c > a) {
          callback && callback();
        }
      }
    },
  },
  mounted() {
    this.imgLoad();
    window.addEventListener("resize", this.imgLoad, false);
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("resize", this.imgLoad, false);
  },
};
</script>

<style lang="scss" scoped>
.home {
  padding-top: 91px;
}

.swiper {
  position: relative;
  .carousel {
    position: relative;

    .swiperItem {
      width: 100%;
      height: 100%;
      position: relative;
      .bgImg {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
      }
      .name {
        color: #2e3e50;
        font-family: PingFang SC;
        font-size: 50px;
        font-style: normal;
        font-weight: 600;
        letter-spacing: 5px;
      }
      .info {
        width: 463px;
        color: #3d5066;
        font-family: PingFang SC;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 164%; /* 32.8px */
        letter-spacing: 0.9px;
      }
      .btns {
        width: 150px;
        height: 45px;
        border-radius: 10px;
        background: #edf5ff;
        .left {
          flex: 1;
          text-align: center;
          color: #3d5066;
          font-family: PingFang SC;
          font-size: 15px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: 0.225px;
        }
        .right {
          width: 45px;
          height: 45px;
          border-radius: 10px;
          background: #0f67fe;

          img {
            width: 28px;
            height: 28px;
          }
        }
      }
    }
  }
  .el-carousel__item img {
    width: 100%;
  }

  ::v-deep .el-carousel__indicators--horizontal {
    left: unset;
    transform: unset;
    bottom: 105px !important;
    right: 360px !important;

    .el-carousel__indicator--horizontal button {
      width: 52px;
      height: 10px;
      background: #ffffff;
      opacity: 1;
    }

    .el-carousel__indicator--horizontal.is-active button {
      width: 25px;
      height: 10px;
      background: #0f67fe;
      opacity: 1;
    }
  }
}

.toolBox {
  width: 100%;
  height: 112px;
  position: absolute;
  top: 100%;
  left: 0;
  transform: translateY(-50%);
  z-index: 9;
  .toolLvBox {
    width: 100%;
    .toolItem:last-child {
      margin-right: 0;
    }
    .toolItem {
      width: 16%;
      height: 112px;
      background: linear-gradient(
        180deg,
        #f3f4f7 0%,
        #ffffff 100%,
        #ffffff 100%
      );
      box-shadow: 1px 7px 11px 1px rgba(232, 236, 243, 0.8),
        inset 4px 4px 8px 0px rgba(255, 255, 255, 0.25);
      border-radius: 4px;
      border: 3px solid #ffffff;
      padding-left: 42px;
      transition: all 0.2s linear;

      .topBox {
        img {
          width: 31px;
          height: 31px;
        }
        .name {
          font-size: 21px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #181818;
        }
      }
      .subName {
        font-size: 17px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #a1aaba;
      }
    }
    .toolItem:hover {
      box-shadow: 1px 7px 14px 3px rgba(218, 225, 236, 0.9),
        inset 4px 4px 8px 0px rgba(255, 255, 255, 0.25);
      .name {
        color: #3266f6;
      }
    }
  }
}
.technologyBox {
  height: 832px;
  background: #fff;
  padding: 200px 0 222px;
  .techSwiperBox {
    margin: auto;
    width: 1200px;
    height: 410px;
    box-shadow: 2px 2px 15px 0px #e0e4ec;
    .swiperItem {
      width: 1200px;
      height: 410px;
      padding: 20px 100px 20px 20px;
      border-radius: 6px;
      background: #fff;

      .leftBox {
        width: 480px;
        height: 370px;
        border-radius: 4px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .rightBox {
        height: 100%;
        margin-left: 50px;
        .btns {
          width: 245px;
          height: 36px;
          border-radius: 48px;
          background: #f3f3f3;

          .btn {
            width: 94px;
            height: 28px;
            border-radius: 48px;
            background: #fff;
            img {
              width: 14px;
              height: 14px;
            }
            span {
              color: #3266f6;
              font-family: PingFang SC;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              letter-spacing: 0.42px;
            }
          }
        }
        .title {
          color: #333;
          font-family: PingFang SC;
          font-size: 30px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        .info {
          max-width: 500px;
          color: #505968;
          font-family: PingFang SC;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: 0.48px;
        }
      }
    }
  }

  .swiper-zhishi {
    margin-top: 72px;
    .zhishiItem:last-child {
      margin-right: 0;
    }
    .zhishiItem {
      width: 84px;
      height: 6px;
      border-radius: 8px;
      background: #fff;
      box-shadow: 2px 2px 6px 0px #e4e9f7;
      &.swiperActive {
        box-shadow: 2px 2px 9px 0px #c2d0f2;
      }
    }
  }
}
.manySceneBox {
  height: 981px;
  padding: 72px 0 106px 0;
  background: #ecf0f4;
  box-shadow: 0px 10px 27px 0px #e8eef5 inset,
    0px -11px 17px 0px rgba(255, 255, 255, 0.3) inset;
  .title {
    color: #0069f8;
    text-align: center;
    font-family: PingFang SC;
    font-size: 35px;
    font-style: normal;
    font-weight: 500;
  }
  .subTitle {
    color: #485261;
    text-align: center;
    font-family: PingFang SC;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
  }
  .bgBox {
    height: 749px;
    position: relative;
    .bgImg {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      img {
        width: 600px;
        height: 600px;
      }
    }
    .bgListBox {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      .bgListItem:hover {
        box-shadow: 10px 10px 28px 0px rgba(193, 199, 215, 0.8);
      }
      .bgListItem {
        border: 2px solid #fff;
        width: 330px;
        height: 134px;
        border-radius: 4px;
        padding: 37px 17px 11px 59px;
        background: linear-gradient(
          90deg,
          rgba(199, 205, 224, 0.2) 0%,
          rgba(158, 177, 212, 0.2) 100%
        );
        box-shadow: 7px 7px 20px 0px rgba(193, 199, 215, 0.5);
        backdrop-filter: blur(8.5px);
        transition: all 0.2s;
        position: absolute;
        .iconImg {
          width: 50px;
          height: 50px;
        }

        .rightBox {
          .name {
            color: #274159;
            font-family: PingFang SC;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
          .subName {
            width: 185px;
            color: #274159;
            font-family: PingFang SC;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
          .more {
            text-align: right;
            color: #4e6d8b;
            font-family: PingFang SC;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
          }
        }
      }
      .bgListItem:nth-child(1) {
        top: 70px;
        left: 110px;
      }
      .bgListItem:nth-child(2) {
        top: 36px;
        left: 630px;
      }
      .bgListItem:nth-child(3) {
        top: 228px;
        left: 820px;
      }
      .bgListItem:nth-child(4) {
        top: 442px;
        left: 800px;
      }
      .bgListItem:nth-child(5) {
        top: 575px;
        left: 450px;
      }
      .bgListItem:nth-child(6) {
        top: 476px;
        left: 60px;
      }
      .bgListItem:nth-child(7) {
        top: 280px;
        left: 18px;
      }
    }
  }
}
.stepBox {
  height: 814px;
  background: #fff;
  padding: 176px 0px 260px;
  .title {
    color: #0069f8;
    text-align: center;
    font-family: PingFang SC;
    font-size: 35px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .subName {
    color: #485261;
    text-align: center;
    font-family: PingFang SC;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .stepList {
    margin-top: 160px;
    .stepItem {
      user-select: none;
      width: 122px;
      height: 118px;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      box-shadow: 10px 10px 28px 0px rgba(193, 199, 215, 0.3);
      border-radius: 2px;
      backdrop-filter: blur(8.5px);
      transition: all 0.2s linear;
      div {
        color: #7197ff;
        text-align: center;
        font-family: PingFang SC;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
    .stepItem:hover {
      box-shadow: 10px 10px 28px 0px rgba(193, 199, 215, 0.67);
      div {
        color: #3e6ff4;
      }
    }
    .arrow {
      width: 28px;
      height: 28px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.juruBox {
  width: 100%;
  height: 360px;
  background-image: url("https://oss.pinmallzj.com/image/maintain/2023/08/31/%E4%BC%81%E4%B8%9A%E5%95%86%E5%8A%A1%E4%BA%A7%E5%93%81%E9%A2%81%E5%A5%96%E5%85%B8%E7%A4%BC%E5%88%9B%E6%84%8F%E6%B8%85%E6%96%B0%E6%B4%BB%E5%8A%A8bannerx1-y5nq0b6sic79.png");
  background-size: cover;
  background-repeat: no-repeat;
  .chinese {
    width: 643px;
    height: auto;
  }
  .eng {
    width: 788px;
    height: auto;
  }
}
.industryBox {
  height: 752px;
  background: #242933;
  box-shadow: 0px 4px 4px 0px #cbd0d7;
  .leftBox,
  .rightBox {
    width: 325px;
    height: 453px;
    .industryItem:hover {
      box-shadow: 11px 11px 23px 0px rgba(255, 255, 255, 0.05),
        -14px -9px 32px 0px rgba(0, 0, 0, 0.32);
    }
    .industryItem {
      user-select: none;
      width: 148px;
      height: 169px;
      border-radius: 2px;
      background: #242933;
      transition: all 0.35s linear;
      box-shadow: -14px -2px 22px 0px rgba(255, 255, 255, 0.04),
        20px 16px 32px 0px rgba(0, 0, 0, 0.3);
      img {
        width: 64px;
        height: 64px;
        transform: translateY(-32px);
      }
      .name {
        color: #fff;
        font-family: PingFang SC;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
      }
      .subName {
        color: #fff;
        opacity: 0.5;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }
    }
  }
  .centerBox {
    width: 456px;
    height: 544px;
    background-image: url("https://oss.pinmallzj.com/image/maintain/2023/08/10/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20230810160554-l92ogv1jdgww.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border-radius: 2px;
    border: 12px solid #242933;
    box-shadow: -12px -2px 22px 0px rgba(255, 255, 255, 0.05),
      20px 16px 32px 0px rgba(0, 0, 0, 0.3);
  }
}
.cooperateBox {
  background: #fff;
  // height: 1040px;
  padding: 74px 100px;
  .title {
    color: #0069f8;
    text-align: center;
    font-family: PingFang SC;
    font-size: 35px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .entList {
    margin-top: 104px;
    background-image: url("https://oss.pinmallzj.com/image/maintain/2023/08/31/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20230831164121-bts11ywaya19.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .entItem {
      width: 520px;
      .name {
        color: #333a45;
        font-family: PingFang SC;
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.259px;
        position: relative;
        &::before {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          display: inline-block;
          content: "";
          width: 14px;
          height: 14px;
          border-radius: 50%;
          background: linear-gradient(161deg, #a8afee 0%, #4e77e4 100%);
          filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.2));
        }
      }
      .subName {
        color: #627597;
        font-family: PingFang SC;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.219px;
      }
    }
  }

  .settledBox {
    margin: auto;
    width: 76%;
    min-width: 1200px;
    height: 219px;
    border-radius: 2px;
    border: 2px solid #fff;
    background: linear-gradient(179deg, #eff3f7 0%, #feffff 100%);
    box-shadow: 1px 5px 21px 0px #e7eaf4;
    padding: 0 174px 0 131px;
    position: relative;
    z-index: 100;
    .name {
      color: #333;
      font-family: PingFang SC;
      font-size: 30px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.3px;
    }
    .subName {
      color: #333;
      font-family: PingFang SC;
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.211px;
    }
    .btn {
      user-select: none;
      width: 232px;
      height: 52px;
      color: #3d6bf6;
      font-family: PingFang SC;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.178px;
      border-radius: 2px;
      border: 1px solid #fff;
      background: linear-gradient(180deg, #fafcff 0%, #fff 100%);
      transition: all 0.5s;
      text-align: center;
      line-height: 52px;
      &:hover {
        box-shadow: 2px 2px 9px 0px #e4e9f7;
      }
    }
  }
}
</style>

import { render, staticRenderFns } from "./dialog.vue?vue&type=template&id=180e17ae&scoped=true&"
import script from "./dialog.vue?vue&type=script&lang=js&"
export * from "./dialog.vue?vue&type=script&lang=js&"
import style0 from "./dialog.vue?vue&type=style&index=0&id=180e17ae&prod&less=scss&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "180e17ae",
  null
  
)

export default component.exports